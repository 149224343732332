import React from "react";
import { Box, Typography, Avatar, IconButton } from "@mui/material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import RepeatIcon from "@mui/icons-material/Repeat";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import BarChartIcon from "@mui/icons-material/BarChart";
import twitterAvatar01 from "../../../assests/images/twitterAvatars/twitterAvatar01.jpg";
import twitterAvatar02 from "../../../assests/images/twitterAvatars/twitterAvatar02.jpg";
import twitterAvatar03 from "../../../assests/images/twitterAvatars/twitterAvatar03.jpg";
import twitterAvatar04 from "../../../assests/images/twitterAvatars/twitterAvatar04.jpg";

// Sample tweets (comments)
const tweets = [
  {
    username: "Cecilia Cerafini",
    handle: "@ceciliacerafini",
    content:
      "Luciano is an excellent developer and team player. I could see his professionalism, attention to detail, and responsibility in every project he took part in. He's not just skilled in his field but also shows a high level of courtesy and willingness to help colleagues. 🌟",
    avatar: twitterAvatar01,
    time: "2h",
    stats: { comments: "150", retweets: "300", likes: "2K", views: "8K" },
  },
  {
    username: "Aditya Manikyala",
    handle: "@adityamanikyala",
    content:
      "It was a great pleasure to work with Luciano. He is a good co-worker and is always willing to help. he is an excellent teammate and professional and a  good friend. He is a very good front-end developer and problem solving and algorithm skills are just amazing. 🙏",
    avatar: twitterAvatar02,
    time: "5h",
    stats: { comments: "70", retweets: "200", likes: "1.5K", views: "6K" },
  },
  {
    username: "Arjun Narain",
    handle: "@arjunnarain",
    content:
      "Luciano is an incredible leader and frontend engineer!🚀 Working with him was a great experience and I learned a lot from him. He was always willing to help and share his knowledge. It was a pleasure and an amazing experience to work with Luciano on the project.",
    avatar: twitterAvatar03,
    time: "1d",
    stats: { comments: "500", retweets: "700", likes: "5K", views: "25K" },
  },
  {
    username: "Gonzalo Farré",
    handle: "@gonzaofarre",
    content:
      "I recommend Luciano for all kind of tasks related to Frontend and Backend technologies. Amazing co-worker with valuable knoledge in React js and React Native. 😊",
    avatar: twitterAvatar04,
    time: "3d",
    stats: { comments: "80", retweets: "150", likes: "1K", views: "4K" },
  },
  // {
  //   username: "Business Guru",
  //   handle: "@bizguru",
  //   content:
  //     "You bring a level of expertise that sets the bar high for others. A true professional. 💼",
  //   avatar: "https://via.placeholder.com/50",
  //   time: "1w",
  //   stats: { comments: "120", retweets: "400", likes: "3K", views: "12K" },
  // },
];

// Individual Twitter Post Component
const TwitterPost = ({ tweet }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        padding: 2,
        backgroundColor: "#fff",
        color: "#000",
        border: "1px solid #e1e8ed",
        borderRadius: 2,
        boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
        wordWrap: "break-word", // Prevent text overflow
        flex: "1 1 auto",
        minHeight: "180px", // Fixed minimum height
      }}
    >
      {/* Avatar */}
      <Avatar src={tweet.avatar} alt={tweet.username} sx={{ width: 48, height: 48 }} />

      {/* Content */}
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        {/* User Info */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography fontWeight="bold">{tweet.username}</Typography>
          <Typography color="grey" variant="caption">
            {tweet.handle} · {tweet.time}
          </Typography>
        </Box>

        {/* Tweet Content */}
        <Typography
          variant="body2"
          sx={{ marginTop: 1, whiteSpace: "pre-line", color: "#000" }}
        >
          {tweet.content}
        </Typography>

        {/* Footer (Engagement Stats) */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 2,
            color: "#657786",
          }}
        >
          <IconButton size="small" color="inherit">
            <ChatBubbleOutlineIcon fontSize="small" />
            <Typography variant="caption" sx={{ marginLeft: 0.5 }}>
              {tweet.stats.comments}
            </Typography>
          </IconButton>

          <IconButton size="small" color="inherit">
            <RepeatIcon fontSize="small" />
            <Typography variant="caption" sx={{ marginLeft: 0.5 }}>
              {tweet.stats.retweets}
            </Typography>
          </IconButton>

          <IconButton size="small" color="inherit">
            <FavoriteBorderIcon fontSize="small" />
            <Typography variant="caption" sx={{ marginLeft: 0.5 }}>
              {tweet.stats.likes}
            </Typography>
          </IconButton>

          <IconButton size="small" color="inherit">
            <BarChartIcon fontSize="small" />
            <Typography variant="caption" sx={{ marginLeft: 0.5 }}>
              {tweet.stats.views}
            </Typography>
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

// Container for Multiple Posts
const TwitterPostContainer = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 3, // Increased gap for spacing between boxes
        justifyContent: "center",
        padding: { xs: 0, sm: 0, md: 3 },
        paddingTop: { xs: 2 },
        backgroundColor: "#fffff",
        // minHeight: "100vh",
        alignItems: 'center'
      }}
    >
      {tweets.map((tweet, index) => (
        <Box
          key={index}
          sx={{
            flex: "1 1 300px", // Ensures responsive widths with minimum size
            maxWidth: { md:"500px" }, // Maximum width for larger screens
          }}
        >
          <TwitterPost tweet={tweet} />
        </Box>
      ))}
    </Box>
  );
};

export default TwitterPostContainer;
