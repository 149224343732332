import React from 'react';
import { Box, Typography, Card, CardMedia, CardContent } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Image } from 'react-bootstrap';
import me from '../../../../assests/images/me.png';
import TwitterPost from '../TwitterPosts';
import './AboutMe.css';

const AboutMe = () => {
  return (
    <Grid 
      container 
      spacing={4} 
      alignItems="center"
      sx={{
        p: { xs: '20px', sm: 4, md: 4 },
        minHeight: { sm: "100vh" },
      }}
    >
      {/* Left Section - Image */}
      <Grid size={{ xs: 12, md: 5 }}>
        <Image src={me} alt="Luciano Fernandez Picture" className="img-fluid lucianopic" style={{ borderRadius: "20px", maxHeight: "100vh"}}/>
      </Grid>

      {/* Right Section - Text */}
      <Grid size={{ xs: 12, md: 7 }}>
        <CardContent sx={{ p: { xs: '0px', sm: '0px' }, pb: { xs: '20px', sm: '20px' } }}>
          <Typography variant="h4" component="h2" gutterBottom sx={{ fontWeight: 'bold' }}>
            About Me | Supernova Encoder
          </Typography>
          <Typography variant="body1" paragraph>
            My ideas and vision focus on growing my company, achieving excellence, and creating impactful solutions. However, I remain open to hearing about new opportunities that align with my skills and passion.
          </Typography>
          <Typography variant="body1" paragraph>
            I have many years of experience in software development, working with a wide range of clients to deliver successful projects.
          </Typography>
          <Typography variant="body1">
            Let’s collaborate and innovate together to build something remarkable.
          </Typography>
        </CardContent>

        <TwitterPost />
      </Grid>
    </Grid>
  );
};

export default AboutMe;
