import { useState, useEffect } from 'react';
import { Col, Row, Image, Modal, Button, Spinner } from 'react-bootstrap';
import { logEvent } from "firebase/analytics";
import { analytics } from '../../../index';
import './StaticModalStyles.css';
import lucianoResume from '../../../assests/documents/Luciano Fernandez CV.pdf';
import lucianoFernandezCvImage from '../../../assests/images/luciano-fernandez-cv-image.png';

function StaticModal(prop) {
  const handleClose = () => prop.handleClose();
  const [didLoad, setLoad] = useState(false);

  const handleDownloadResume = () => {
    // using Java Script method to get PDF file
    fetch(lucianoResume).then((response) => {
      response.blob().then((blob) => {
       
          // Creating new object of PDF file
          const fileURL =
              window.URL.createObjectURL(blob);
               
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = "Luciano Fernandez CV.pdf";
          alink.click();
      });
    });

    // logEvent(analytics, 'downloaded_resume');
    logEvent(analytics, 'downloaded_resume', { downloaded_resume: 'yes' });
  };

  return (
    <>
      <Modal
        show={prop.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Get a copy of my resume</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
              <Image src={lucianoFernandezCvImage} thumbnail onLoad={() => setLoad(true)} style={{display: didLoad ? 'block' : 'none'}}/> 
              {!didLoad && 
                <div className='loader-wrapper'>
                  <Spinner animation="border"/>
                </div>
              }
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleDownloadResume}>Download</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default StaticModal;

