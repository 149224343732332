import React, { useEffect, useState } from 'react';
import ResponsiveAppBar from '../ResponsiveAppBar/ResponsiveAppBar.tsx';
import { 
  CheckSquare,
  MapPin,
  Globe
} from 'react-feather';
import {
  Image,
  Row,
  Col,
  Button
} from 'react-bootstrap';
import Grid from '@mui/material/Grid2';
import './dashboardMainViewStyles.css';
import StaticModal from '../common/modal/StaticModal.jsx';
import userWspImage from '../../assests/images/user-wsp.jpg';
import illustration1 from '../../assests/images/illustration-1.svg';
import illustration2 from '../../assests/images/illustration-2.svg';
import illustration3 from '../../assests/images/illustration-3.svg';
import icono1 from '../../assests/icons/icono1.svg';
import icono2 from '../../assests/icons/icono2.svg';
import icon4 from '../../assests/icons/icon4.svg';
import icon5 from '../../assests/icons/icon5.svg';
import icon6 from '../../assests/icons/icon6.png';
import icon7 from '../../assests/icons/icon7.jpeg';
import icon8 from '../../assests/icons/icon8.svg';
import icon9 from '../../assests/icons/icon9.png';
import icon10 from '../../assests/icons/icon10.svg';
import icon11 from '../../assests/icons/icon11.png';
import icon12 from '../../assests/icons/icon12.png';
import icon13 from '../../assests/icons/icon13.jpeg';
import useWindowSize from '../utils/windowSize.js';
import FloatingWhatsApp from 'react-floating-whatsapp'
import TwoPaneLayout from './Sections/TwoPaneLayout.jsx';
import AboutMe from './Sections/AboutMe/AboutMe.jsx';
import ContactSection from './Sections/ContactSection.jsx';
import Portfolio from './Sections/Portfolio.jsx';
import { Typography, ThemeProvider, ImageList, ImageListItem } from "@mui/material";
import { headingFont, headingSubsectionFont } from '../../styles/themes.tsx';
import RocketLaunchTwoToneIcon from '@mui/icons-material/RocketLaunchTwoTone';
import WorkspacePremiumTwoToneIcon from '@mui/icons-material/WorkspacePremiumTwoTone';

export default function DashboardMainView(props) {
  const { 
    getBusStopsSaga, 
    isLoadingBusStops
  } = props;
  const [showResumeModal, setShowResumeModal] = useState(false);
  
  const size = useWindowSize();

  useEffect( () => {
    getBusStopsSaga();
  }, []);

  useEffect(() => {
    // Handle smooth scrolling and dynamic nav height adjustment
    const handleSmoothScroll = (e) => {
      e.preventDefault();

      const targetId = e.currentTarget.getAttribute("href").substring(1); // Remove the '#' from href
      const targetSection = document.getElementById(targetId);

      if (targetSection) {
        // Get nav bar height based on window width
        const navHeight = size.width <= 768 ? 55 : 68; // Mobile: 55px, Web: 68px

        // Scroll to section with offset
        window.scrollTo({
          top: targetSection.offsetTop - navHeight,
          behavior: "smooth",
        });
      }
    };

    // Attach event listeners to all navigation links
    const navLinks = document.querySelectorAll("a");
    navLinks.forEach((link) => link.addEventListener("click", handleSmoothScroll));

    // Cleanup event listeners
    return () => {
      navLinks.forEach((link) => link.removeEventListener("click", handleSmoothScroll));
    };
  }, [size]);

  return (
    <div>
      <ResponsiveAppBar />
      <section id="home">
        <div className="text-center">
          <div className="phone-wrapper">
            <div className='main'></div>
            <div className="phone">
              <div className="phone-screen">
                <div className="phone-top-noch"></div>
                <div>
                  <Image className="illustration-3" src={illustration3} alt="illustration" />
                </div>
              </div>
            </div>
          </div>
          <Image className="illustration-1" src={illustration1} alt="illustration" />
          <Image className="illustration-2" src={illustration2} alt="illustration2" />
          <Grid sx={{ 
            top: { xs: '66px', sm: "74px", md: "78.5px" },
            position: "absolute",
            left: "10px",
            marginRight: "10px",
          }}>
            <h1 className="display-4 font-weight-bold dashboard__main-text">
              Take off your&nbsp;business&nbsp;
              <RocketLaunchTwoToneIcon sx={{ fontSize: size.width < 775 ? "50px" : "100px", color: "chartreuse" }}></RocketLaunchTwoToneIcon>
            </h1>
          </Grid>
        </div>

        <Grid className="d-md-flex flex-md-equal w-100" sx={{ minHeight: { lg: "50vh" }} }>
          {isLoadingBusStops ?
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
            :
            <Grid className="text-center overflow-hidden body__money-box-conatiner"
              sx={{ p: { xs: '10px', sm: "20px", md: "40px 110px 0px 100px" }}}>
              <div className="my-3 p-3">
                <div className="d-flex justify-content-center align-items-center">
                  <ThemeProvider theme={headingFont}>
                    <Typography type="h2" sx={{ fontWeight: 700, fontSize: { xs: "35px", sm: "40px", md: "50px" }, textAlign: "center" }}> 
                      High&nbsp;software quality&nbsp;assured
                    </Typography>
                  </ThemeProvider>
                  <WorkspacePremiumTwoToneIcon sx={{ fontSize: size.width < 775 ? "70px" : "100px", fill: "#ffc300", stroke: "#6c757d" }}></WorkspacePremiumTwoToneIcon>
                </div>
                <ThemeProvider theme={headingSubsectionFont}>
                  <Typography sx={{ fontWeight: 300, fontSize: { xs: "19px" }, textAlign: {xs:"left", sm:"center"}, mb: 2}}>
                    Meet customer demands by complying with strict protocols, to deliver a product of excellence that exceeds customer expectations.
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography sx={{ fontWeight: 700, fontSize: { xs: "19px", sm: "20px", md: "30px" }, textAlign: "center"}}>
                    We deliver professional services of web and mobile development
                  </Typography>
                </ThemeProvider>
              </div>
            </Grid>
          }
        </Grid>
      </section>

      <TwoPaneLayout></TwoPaneLayout>

      <Portfolio></Portfolio>

      <section id="about-us">
        <AboutMe></AboutMe>
      </section>

      <Grid className="d-md-flex flex-md-equal w-100 bg-secondary dashboard__payment-container" sx={{ minHeight: { lg: "100vh" }, p: { xs:"20px", md: 3, lg: 4} }}>
        <div className="p-6 text-center text-white">
          <div className="my-3 py-3">
            <div className="d-flex justify-content-center align-items-center">
              <h2 className="body__title">Take action | Elevate your business</h2>
              <div>
              <CheckSquare className='check-sqre-icon' color='green' />
              </div>
            </div>
            <div className='contact-container'>
              <p>Applying last technologies of the market</p>
              <ImageList className='tech-icon-container' rowHeight="auto" cols={size.width < 990 ? (size.width < 500 ? 3 : 4) : 6} gap={30}>
                <ImageListItem key={icono1} className="tech-icon">
                  <img src={icono1} srcSet={icono1} alt={icono1} loading="lazy"/>
                </ImageListItem>
                <ImageListItem key={icon5} className="tech-icon">
                  <img src={icon5} srcSet={icon5} alt={icon5} loading="lazy"/>
                </ImageListItem>
                <ImageListItem key={icono2} className="tech-icon">
                  <img src={icono2} srcSet={icono2} alt={icono2} loading="lazy"/>
                </ImageListItem>
                <ImageListItem key={icon4} className="tech-icon">
                  <img src={icon4} srcSet={icon4} alt={icon4} loading="lazy"/>
                </ImageListItem>
                <ImageListItem key={icon7} className="tech-icon">
                  <img src={icon7} srcSet={icon7} alt={icon7} loading="lazy"/>
                </ImageListItem>
                <ImageListItem key={icon6} className="tech-icon">
                  <img src={icon6} srcSet={icon6} alt={icon6} loading="lazy"/>
                </ImageListItem>
                <ImageListItem key={icon8} className="tech-icon">
                  <img src={icon8} srcSet={icon8} alt={icon8} loading="lazy"/>
                </ImageListItem>
                <ImageListItem key={icon9} className="tech-icon">
                  <img src={icon9} srcSet={icon9} alt={icon9} loading="lazy"/>
                </ImageListItem>
                <ImageListItem key={icon10} className="tech-icon">
                  <img src={icon10} srcSet={icon10} alt={icon10} loading="lazy"/>
                </ImageListItem>
                <ImageListItem key={icon11} className="tech-icon">
                  <img src={icon11} srcSet={icon11} alt={icon11} loading="lazy"/>
                </ImageListItem>
                <ImageListItem key={icon12} className="tech-icon">
                  <img src={icon12} srcSet={icon12} alt={icon12} loading="lazy"/>
                </ImageListItem>
                <ImageListItem key={icon13} className="tech-icon">
                  <img src={icon13} srcSet={icon13} alt={icon13} loading="lazy"/>
                </ImageListItem>
              </ImageList>
              <p>Building Web pages with JavaScript frameworks</p>
              <p>Mobile apps for IOS and Android platform with React Native</p>
            </div>
          </div>
        </div>
      </Grid>

      <div>
        <FloatingWhatsApp className='wsp-icon' phoneNumber="+59892708876" accountName="Luciano" avatar={userWspImage}/>
      </div>

      <section id="contact">
        <ContactSection></ContactSection>
      </section>
      
      <StaticModal show={showResumeModal} handleClose={() => setShowResumeModal(false)}></StaticModal>

      <footer className="container py-5">
        <Row>
          <Col xxs={12} md={4}>
            <div className='linkedin-wrapper'>
              <Button variant="primary" onClick={() => setShowResumeModal(true)}>
                Get Luciano Fernandez CV
              </Button>
            </div>
            <div className='linkedin-wrapper'>
              <a href="https://www.linkedin.com/in/lucianomfernandez/" target="_blank">
                <Image className="linkedinImage" src="https://fatimamartinez.es/wp-content/uploads/2019/02/Linkedin-logo.png" alt="linkedin"/>
              </a>
            </div>
          </Col>
          <Col xxs={12} md={4}>
            <h5>Location</h5>
            <ul className="list-unstyled text-small">
              <li><span className="text-muted">Montevideo, Uruguay<MapPin color='red'className='icon-left-space '/></span></li>
              <li><span className="text-muted">We deliver to the world<Globe color='green' className='icon-left-space '/></span></li>
            </ul>
          </Col>
          <Col xxs={12} md={4}>
            <h5>Contact us</h5>
            <ul className="list-unstyled text-small">
              <li><span className="text-muted">luciano.fndz@gmail.com</span></li>
              <li><span className="text-muted">+59892708876</span></li>
            </ul>
          </Col>
        </Row>
      </footer>
    </div>
  );
};