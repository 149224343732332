import React, { useState } from 'react';
import { Box, Grid, Typography, TextField, Button, Paper, Modal } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, push } from 'firebase/database';
import emailjs from '@emailjs/browser';
import { db } from '../../../index.js';
import { collection, addDoc } from 'firebase/firestore';
import { getAnalytics, logEvent } from "firebase/analytics";
import { analytics } from '../../../index.js';

const ContactSection = () => {
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [touched, setTouched] = useState({
    name: false,
    email: false,
    phone: false,
    message: false,
  });

  const validate = (name, value) => {
    switch (name) {
      case 'name':
        if (!value) return 'Name is required';
        return '';
      case 'email':
        if (!value) return 'Email is required';
        if (!/\S+@\S+\.\S+/.test(value)) return 'Email is invalid';
        return '';
      case 'phone':
        if (!value) return 'Phone is required';
        if (isNaN(value)) return 'Phone must be a number.';
        if (value.length > 15) return 'Phone must not exceed 15 characters';
        return '';
      case 'message':
        if (!value) return 'Message is required';
        if (value.length < 10) return 'Message must be at least 10 characters';
        return '';
      default:
        return '';
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setTouched({ ...touched, [name]: true });
    setErrors({ ...errors, [name]: validate(name, value) });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (touched[name]) {
      setErrors({ ...errors, [name]: validate(name, value) });
    }
    logEvent(analytics, 'user_typing', { name: name , value: value });
  };

  const handleSubmit = async (e) => {

    e.preventDefault();
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      newErrors[key] = validate(key, formData[key]);
    });
    setErrors(newErrors);

    const isValid = Object.values(newErrors).every((error) => !error);
    if (isValid) {
      console.log('Form data:', formData);
      // Handle successful form submission

      const payload = { ...formData, date: new Date().toISOString() };
    
      try {
        setIsLoadingSubmit(true);
        // Save to Firebase
        await addDoc(collection(db, 'messages'), payload);
  
        // Send Email using EmailJS
        await emailjs.send(
          'service_hyd5y8n',
          'template_fanuqds',
          {
            from_name: formData.name,
            from_email: formData.email,
            phone: formData.phone,
            message: formData.message,
            date: new Date().toISOString(),
          },
          'kMtzLrqR5OtfhWNEO'
        )
        setIsLoadingSubmit(false);
        setSuccessModalOpen(true);
      } catch (error) {
        setIsLoadingSubmit(false);
        setErrorModalOpen(true);
      }
      logEvent(analytics, 'user_submit', { ...payload });
  
      // Reset form after submission
      setFormData({ 
        name: '',
        email: '',
        phone: '',
        message: ''
      });
    }
  };

  const isFormValid = Object.values(errors).every((error) => !error) &&
  Object.values(formData).every((value) => value.trim() !== '');

  return (
    <Box sx={{ p: {xs: "20px", sm: "30px", md: 4}, backgroundColor: '#999999' }}>
      <Grid container spacing={4}>
        {/* Left Section: Contact Information */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h5" gutterBottom>
              Contact Information
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Email:</strong> luciano.fndz@gmail.com
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Phone:</strong> +59892708876
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Feel free to reach out via email or phone for any inquiries.
            </Typography>
          </Paper>
        </Grid>

        {/* Right Section: Contact Form */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h5" gutterBottom>
              Send Us a Message
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Name"
                name="name"
                fullWidth
                margin="normal"
                value={formData.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.name)}
                helperText={errors.name}
              />
              <TextField
                label="Email"
                name="email"
                fullWidth
                margin="normal"
                type="email"
                value={formData.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.email)}
                helperText={errors.email}
              />
              <TextField
                label="Phone"
                name="phone"
                fullWidth
                margin="normal"
                value={formData.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.phone)}
                helperText={errors.phone}
              />
              <TextField
                label="Message"
                name="message"
                fullWidth
                margin="normal"
                multiline
                rows={4}
                value={formData.message}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.message)}
                helperText={errors.message}
              />
              <Button
                type="submit"
                variant="contained"
                endIcon={<SendIcon />}
                sx={{ mt: 2 }}
                disabled={isLoadingSubmit || !isFormValid}
              >
                {isLoadingSubmit ? "Submitting..." : "Submit"}
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>


      {/* Success Modal */}
      <Modal
        open={successModalOpen}
        onClose={() => setSuccessModalOpen(false)}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <Paper sx={{ p: 3, maxWidth: 400, margin: 'auto', mt: 10 }}>
          <Typography id="success-modal-title" variant="h6" gutterBottom>
            Success
          </Typography>
          <Typography id="success-modal-description" variant="body1">
            Your message has been sent successfully!
          </Typography>
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            onClick={() => setSuccessModalOpen(false)}
          >
            Close
          </Button>
        </Paper>
      </Modal>

      {/* Error Modal */}
      <Modal
        open={errorModalOpen}
        onClose={() => setErrorModalOpen(false)}
        aria-labelledby="error-modal-title"
        aria-describedby="error-modal-description"
      >
        <Paper sx={{ p: 3, maxWidth: 400, margin: 'auto', mt: 10 }}>
          <Typography id="error-modal-title" variant="h6" gutterBottom>
            Error
          </Typography>
          <Typography id="error-modal-description" variant="body1">
            There was an issue sending your message. Please try again later.
          </Typography>
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            onClick={() => setErrorModalOpen(false)}
          >
            Close
          </Button>
        </Paper>
      </Modal>
    </Box>
  );
};

export default ContactSection;
