import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import DashboardContainer from './containers/dashboardContainer';
import store from './store/store';
import { logEvent } from "firebase/analytics";
// @ts-ignore
import { analytics } from './index';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

function App() {
  const [startTime] = useState(Date.now());
  const [maxScrollPercent, setMaxScrollPercent] = useState(0);

  const isBotVisit = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /bot|crawl|spider|slurp|bing|duckduckbot|baiduspider|yandex|sogou|exabot|facebot|ia_archiver/.test(userAgent);
  };

  const trackScrollDepth = () => {
    const scrollPosition = window.scrollY + window.innerHeight;
    const scrollHeight = document.documentElement.scrollHeight;
    const currentScrollPercent = Math.trunc((scrollPosition / scrollHeight) * 100);

    if (currentScrollPercent > maxScrollPercent && [20, 40, 60, 80, 100].includes(currentScrollPercent)) {
      setMaxScrollPercent(currentScrollPercent);
      logEvent(analytics, `reached_scroll_${currentScrollPercent}%`);
    }
  };

  const logBrowserAndHardwareInfo = () => {
    const { userAgent, hardwareConcurrency, deviceMemory } = navigator;
    const platform = navigator.userAgentData?.platform || 'unknown';

    logEvent(analytics, 'browser_hardware_info', {
      user_agent: userAgent,
      platform,
      hardware_concurrency: hardwareConcurrency,
      memory: deviceMemory
    });
  };

  const logVisitEvent = () => {
    const event = isBotVisit() ? 'bot_visit' : 'user_visit';
    logEvent(analytics, event);
  };

  const handleUnload = () => {
    const timeSpent = (Date.now() - startTime) / 1000; // converts the time spent from milliseconds to seconds
    logEvent(analytics, 'time_spent', { time_spent_seconds: timeSpent, max_scroll_percent: maxScrollPercent });
  };

  useEffect(() => {
    logVisitEvent();
    logBrowserAndHardwareInfo();

    const handleScroll = () => {
      trackScrollDepth();
    };

    const handleBeforeUnload = () => {
      handleUnload();
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [maxScrollPercent]);

  return (
    <Provider store={store}>
      <DashboardContainer />
    </Provider>
  );
}

export default App;
