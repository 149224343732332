import { createTheme } from '@mui/material/styles';

export const headingFont = createTheme({
  typography: {
    fontFamily: [
      'Arial', 'Helvetica', 'sans-serif'
    ].join(','),
},});

export const headingSubsectionFont = createTheme({
  typography: {
    fontFamily: [
      "Segoe UI", "Helvetica Neue", "Noto Sans", "Liberation Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
    ].join(','),
},});


