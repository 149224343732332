import React from "react";
import { Box, Button, Typography, Divider } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { makeStyles } from "@mui/styles";
import {
  Image
} from 'react-bootstrap';
import GradingIcon from '@mui/icons-material/Grading';
import InsightsIcon from '@mui/icons-material/Insights';
import HailIcon from '@mui/icons-material/Hail';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import office01 from '../../../assests/images/office01.jpg';
import office02 from '../../../assests/images/office02.jpg';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const useStyles = makeStyles((theme) => ({
  container: {
  },
  leftPane: {
    // padding: 30,
  },
  leftPaneSection2: {
  },
  imageLeft: {
    width: "100%",
    height: "100%",
    borderRadius: "20px",
  },
  container2: {
    backgroundColor: '#040B1C',
    color: "white"
  }
}));


const TwoPaneLayout = () => {
  const classes = useStyles();

  return (
    <div>
      <Grid container className={classes.container} sx={{ minHeight: { lg: "100vh" }} }>
        {/* Static Left Pane */}
        <Grid size={{ xs: 12, md: 4 }} className={classes.leftPane} sx={{ padding: { xs:"20px", sm:"30px" } }}>
          <Image src={office02} className={classes.imageLeft}/>
        </Grid> 

        {/* Scrollable Right Pane */}
        <Grid size={{ xs: 12, md: 8 }} sx={{ padding: { xs:"20px", sm:"30px", md:"20px" }}}>


          <Box sx={{ padding: { xs: 0, sm:0, md:"0px 40px" } , backgroundColor: "#FFFFFF" }}>

            <Box sx={{ display: 'flex', alignItems: 'center'}}>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: "bold",
                  marginRight: "8px",
                  backgroundColor: "green",
                  color: "white",
                  padding: "8px",
                  borderRadius: "10px",
                  fontSize: { xs: "26px", sm: "30px", md: "40px" },
                }}
              >
                Affordable Software&nbsp;Solutions
              </Typography>
              <TaskAltIcon sx={{ fontSize: {xs: "48px", sm: "60px", md: "70px"}, color: "green" }} />
            </Box>

            <Typography
              variant="body1"
              sx={{
                marginTop: "16px",
                color: "#555555",
                lineHeight: "1.8",
                maxWidth: "600px",
              }}
            >
              At Supernova Encoder, we believe cutting-edge software shouldn’t cost a fortune. 
              By partnering with talented professionals from Latin America—where salaries are often more accessible than in other regions, we use AI tools to maximize the value of our services. With a focus on affordability and quality, we bring you high-quality software solutions at prices that won’t break the bank.
            </Typography>

            <Typography
              variant="h4"
              sx={{ marginTop: "16px", fontWeight: "bold" }}
            >
              Why Choose Us?
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginTop: "8px", color: "#555555", lineHeight: "1.8" }}
            >
              <strong>Affordable Pricing:</strong> Save big without compromising on quality.
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginTop: "8px", color: "#555555", lineHeight: "1.8" }}
            >
              <strong>Skilled Professionals:</strong> Our team of experts delivers reliable and innovative software tailored to your needs using AI tools to develop.
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginTop: "8px", color: "#555555", lineHeight: "1.8" }}
            >
              <strong>Ethical Practices:</strong> We empower communities by creating opportunities for talented developers in Latin America.
            </Typography>

            <Typography
              variant="h4"
              sx={{ marginTop: "32px", fontWeight: "bold" }}
            >
              Have Questions? Let’s&nbsp;Talk!
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginTop: "8px", color: "#555555", lineHeight: "1.8" }}
            >
              Confused about which solution works best for your business? Don’t worry! We’re here to answer all your questions and help you find the perfect solution to meet your goals.
            </Typography>
          </Box>
        </Grid>
      </Grid>



      <Grid container className={classes.container2} sx={{ minHeight: { lg: "100vh" }} }>
        <Grid 
          container size={{ xs: 12, md: 7 }} 
          className={classes.leftPaneSection2} 
          rowSpacing={0} 
          columnSpacing={{ xs: 1, sm: 1, md: 0 }}
          order={{ xs: 2, sm: 2, md: 1 }}
        >
          <Box sx={{ padding: { xs: "20px", sm:"10px 30px 0px 30px" } }}>
            <Typography
              variant="h4"
              sx={{ marginTop: { xs: "0px", sm: "0px", md: "20px"}, fontWeight: "800",}}
            >
              Are you owner of a shop, gym, restaurant, professional as a lawyer or any other business? 
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginTop: "16px", lineHeight: "1.8" }}
            >
              We can help you creting a website or landing page to promote your business.
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginTop: "8px", lineHeight: "1.8" }}
            >
              We’re always looking for exciting new projects. Whether you’re a business seeking cost-effective solutions or an individual with innovative ideas, let’s connect.
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginTop: "16px", lineHeight: "1.8" }}
            >
              Contact us today to discover how affordable software can make a difference in your business.
            </Typography>

            <Typography
              variant="body1"
              sx={{ marginTop: "16px", lineHeight: "1.8" }}
            >
              Let’s create something amazing together. Reach out for B2B projects, custom solutions, or to discuss new opportunities, I am open to hear about new opportunities.
            </Typography>
            <Button
              variant="contained"
              href="#contact"
              sx={{
                padding: "16px 30px",
                marginTop: "16px",
                fontWeight: "bold",
                backgroundColor: "#1E3A95", /* dark blue */
                color: "white",
                cursor: "pointer",
                '&:hover': {
                  backgroundColor: "#1E3A5F",
                  color: "white"
                }
              }}
            >
              Contact Us
            </Button>

            <Divider sx={{ marginTop: "20px" }} />

          </Box>

          <Grid container sx={{padding: {xs: "20px", sm: "10px 30px 10px 30px"} }} spacing={2}>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box display="flex" alignItems="center" gap={2}>
                <PriceCheckIcon
                  sx={{ fontSize: "40px", color: "yellow" }}
                />
                <Box>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold" }}
                  >
                    Affordable Pricing
                  </Typography>
                  <Typography variant="body2">
                    Using AI and working with experts from latam, we offer affordable pricing.
                  </Typography>
                </Box>
              </Box>
            </Grid>
           <Grid size={{ xs: 12, md: 6 }}>
              <Box display="flex" alignItems="center" gap={2}>
                <GradingIcon
                  sx={{ fontSize: "40px", color: "yellow" }}
                />
                <Box>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold" }}
                  >
                    Quality on code
                  </Typography>
                  <Typography variant="body2">
                    We follow all official documentations, estandards and best programming practices.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box display="flex" alignItems="center" gap={2}>
                <InsightsIcon
                  sx={{ fontSize: "40px", color: "yellow" }}
                />
                <Box>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold" }}
                  >
                    Custom software Solutions
                  </Typography>
                  <Typography variant="body2">
                    we undestand your needs and provide custom software solutions to meet your specific requirements.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box display="flex" alignItems="center" gap={2}>
                <HailIcon
                  sx={{ fontSize: "40px", color: "yellow" }}
                />
                <Box>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold" }}
                  >
                    Technical Experts
                  </Typography>
                  <Typography variant="body2">
                    We will hire the best talent that fits your needs.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid 
          size={{ xs: 12, md: 5 }} 
          sx={{padding: {xs: "20px", sm: "10px 30px 30px 30px", md: "30px"}}}
          order={{ xs: 1, sm: 1, md: 2 }}
        >
          <Image src={office01} className={classes.imageLeft} />
        </Grid>
      </Grid>
    </div>
  );
};

export default TwoPaneLayout;
